import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

import { graphql, useStaticQuery } from "gatsby"
import Card from "../components/blog/card/card"
import BlogNav from "../components/blog/nav/nav"

const Blog = () => {

  const data = useStaticQuery(graphql`
    query {
      allDibPosts {
        edges {
          node {
            title
            summary
            slug
            publishedAt
            readtime
            featuredImage
            categories {
              title
              slug
            }
            author {
              name
              slug
              photo
            }
          }
        }
      }
    }
    `);
  const posts = data.allDibPosts.edges

  return (
    <Layout>
      <Seo title="Blog" />
        <div className="container mt-4">
          <div className="blog-header">
            <h2>Blog</h2>
            <BlogNav />
          </div>
          <div className="row">
            {posts.map((post, index) => (
              <div key={index} className="col-sm-6 col-md-6 mb-2">
                <Card post={post.node} tab={post.node.categories[0]} />
              </div>
            ))}
          </div>
        </div>
    </Layout>
  )

}
export default Blog
